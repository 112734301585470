/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.menu-toggle').click(function(){
          $('.hamburger', this).toggleClass('is-active');
          $('.hamburger-container', this).toggleClass('is-active');
          $('.menu-content').slideToggle();
        });


        // Add the following code if you want the name of the file appear on select
        $(".custom-file-input").on("change", function(e) {
          var fileName = $(this).val().split("\\").pop();
          $(this).closest(".custom-file").find(".custom-file-label").addClass("selected").html(fileName);
        });

        $('.slider-tijdlijn-tijd').each(function(index) {
          $(this).slick({
            arrows: false,
            speed: 500,
            centerMode: true,
            centerPadding: '20%',
            slidesToShow: 3,
            focusOnSelect: true,
            infinite: false,
            asNavFor: '.slider-tijdlijn, .slider-tijdlijn-content',
            responsive: [
              {
                breakpoint: 900,
                settings: {
                  centerPadding: '10%',
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  centerPadding: '0',
                  slidesToShow: 3,
                }
              }
            ]
          });
        });

        $('.slider-tijdlijn').each(function(index) {
          $(this).slick({
            arrows: true,
            speed: 500,
            prevArrow: '<div class="slick-arrow arrow-left"><button class="btn btn-primary btn-icoon btn-square"><i class="fas fa-chevron-left"></i></button></div>',
            nextArrow: '<div class="slick-arrow arrow-right"><button class="btn btn-primary btn-icoon btn-square"><i class="fas fa-chevron-right"></i></button></div>',
            centerMode: true,
            centerPadding: '0',
            slidesToShow: 1,
            infinite: false,
            asNavFor: '.slider-tijdlijn-tijd, .slider-tijdlijn-content',
            responsive: [
              {
                breakpoint: 600,
                settings: {
                  centerPadding: '5%',
                  slidesToShow: 1,
                }
              }
            ]
          });
        });

        $('.slider-tijdlijn-content').each(function(index) {
          $(this).slick({
            arrows: false,
            speed: 500,
            swipe: false,
            centerMode: true,
            centerPadding: '0',
            slidesToShow: 1,
            infinite: false,
            asNavFor: '.slider-tijdlijn-tijd, .slider-tijdlijn',
          });
        });

        $('.filter-open').click(function(){
          const arrowIcon = $(this).find('i');

          $(this).next('.form-filters').slideToggle();
          if($(this).hasClass('closed')){
            arrowIcon.removeClass('fa-angle-up');
            arrowIcon.addClass('fa-angle-down');
            $(this).removeClass('closed');
          }else {
            arrowIcon.removeClass('fa-angle-down');
            arrowIcon.addClass('fa-angle-up');
            $(this).addClass('closed');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('.doen').click(function() {
    $('.doen').next().toggleClass('d-none');
  });

  $('.jij').click(function() {
    $('.jij').next().toggleClass('d-none');
  });

  $('.wij').click(function() {
    $('.wij').next().toggleClass('d-none');
  });

})(jQuery); // Fully reference jQuery after this point.
